type InternalSubstores = {
  [key: string]: {
    subdomain: string
    domain: string
    name: string
    brandName: string
    legalName: string
    cvr: string
    contactEmail: string
    primaryColor: string
    secondaryColor: string
    contrastColor: string
    logoUrl: string
    locations: readonly number[]
    address: {
      street: string
      number: string
      city: string
      zipCode: string
    }
    gtmId: string | null
    faviconPath: string
    newsletterListId: string | null
  }
}
export type Substore = typeof substores

const prod = {
  'nissan-danmark': {
      subdomain: 'nissan-danmark',
      domain: 'https://nissandrive.dk',
      name: 'Nissan Danmark',
      brandName: 'Nissan Drive',
      legalName: 'Nissan og Justdrive',
      cvr: '42078395',
      contactEmail: 'hej+nissan@justdrive.today',
      primaryColor: '#C3002F',
      secondaryColor: '#FFFFFF',
      contrastColor: '#FFFFFF',
      // secondaryColor: '#5c5c5c',
      logoUrl: 'https://ruwqcqtlonwdksotboxk.supabase.co/storage/v1/object/public/logos/Nissan-Logo.png',
      locations: [33],
      address: {
        street: 'Lyngbyvej',
        number: '24',
        city: 'København Ø',
        zipCode: '2100',
      },
      gtmId: 'GTM-M599TBSH',
      faviconPath: '/substores/nissan/favicon.png',
      newsletterListId: 'XZLagb',
    },
    'trio-biler-ballerup': {
      subdomain: '',
      name: 'Trio Biler Ballerup',
      brandName: 'Trio Biler',
      legalName: 'TRIO BILER ApS',
      cvr: '31771749',
      contactEmail: 'en@triobiler.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos/trio.png', // Placeholder attached logo
      locations: [16, 35],
      
      address: {
        street: 'Energivej',
        number: '22',
        city: 'Ballerup',
        zipCode: '2750',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'autohuset-vestergaard-kolding': {
      subdomain: '',
      name: 'Autohuset Vestergaard',
      brandName: 'Autohuset Vestergaard',
      legalName: 'Autohuset Vestergaard Personvogne A/S',
      cvr: '189305791',
      contactEmail: 'fleet@autohuset-vestergaard.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos/Autohusetvestergaard.png', // attached logo
      locations: [34, 3, 8],
      address: {
        street: 'Trianglen',
        number: '20',
        city: 'Kolding',
        zipCode: '6000',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'krogsgaard-biler': {
      subdomain: '',
      name: 'Krogsgaard Biler',
      brandName: 'Krogsgaard Biler',
      legalName: 'KROGSGAARD-JENSEN A/S',
      cvr: '21481483',
      contactEmail: 'mikkel.skov.skjellerup@krogsgaard-biler.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos/Krogsgaard.png',
      locations: [24, 30],
      address: {
        street: 'Mesterlodden',
        number: '37',
        city: 'Gentofte',
        zipCode: '2820',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'pedersen-&-nielsen': {
      subdomain: '',
      name: 'Pedersen & Nielsen A/S',
      brandName: 'Pedersen & Nielsen',
      legalName: 'Pedersen & Nielsen Automobilforretning A/S',
      cvr: '14854738',
      contactEmail: 'randers@autohus.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos/pn_logo.png',
      locations: [38],
      address: {
        street: 'Ribevej',
        number: '3',
        city: 'Randers SV',
        zipCode: '8940',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'pma-biler': {
      subdomain: '',
      name: 'PMA biler A/S',
      brandName: 'PMA Biler Roskilde',
      legalName: 'PMABiler A/S',
      cvr: '30435028',
      contactEmail: 'afh@pmabiler.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//PMA_logo.png',
      locations: [39],
      address: {
        street: 'Præstemarksvænge',
        number: '16',
        city: 'Roskilde',
        zipCode: '4000',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'koch-biler': {
      subdomain: '',
      name: 'Koch Biler A/S Viby J',
      brandName: 'Koch Biler',
      legalName: 'Koch Biler A/S',
      cvr: '25623185',
      contactEmail: 'tokoc@koch-biler.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//Koch_Biler_logo.png',
      locations: [37],
      address: {
        street: 'Gunnar Clausens Vej',
        number: '12',
        city: 'Viby J',
        zipCode: '8260',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'andersen-biler': {
      subdomain: '',
      name: 'Andersen Biler - Roskilde',
      brandName: 'Andersen Biler',
      legalName: 'Andersen Biler A/S',
      cvr: '17694707',
      contactEmail: 'RoskildeSalg@andersenbiler.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//Andersen_biler_logo.png',
      locations: [36],
      address: {
        street: 'Københavnsvej',
        number: '154',
        city: 'Roskilde',
        zipCode: '4000',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'bjarne-nielsen': {
      subdomain: '',
      name: 'Bjarne Nielsen A/S Hillerød',
      brandName: 'Bjarne Nielsen A/S',
      legalName: 'Bjarne Nielsen A/S',
      cvr: '31943140',
      contactEmail: 'rental@bn.dk',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//bjarne_nielsen.png',
      locations: [40],
      address: {
        street: 'Falkevej',
        number: '4A',
        city: 'Hillerød',
        zipCode: '3400',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
} as const satisfies InternalSubstores

type KeysInProd = {[K in keyof typeof prod]: InternalSubstores[string]}

const dev = {
  'nissan-danmark': {
    subdomain: 'nissan-danmark',
    domain: 'https://nissandrive.dk',
    name: 'Nissan Danmark',
    brandName: 'Nissan Drive',
    legalName: 'Nissan og Justdrive',
    cvr: '42078395',
    contactEmail: 'hej+nissan@justdrive.today',
    primaryColor: '#C3002F',
    secondaryColor: '#FFFFFF',
    contrastColor: '#FFFFFF',
    // secondaryColor: '#5c5c5c',
    logoUrl: 'https://ruwqcqtlonwdksotboxk.supabase.co/storage/v1/object/public/logos/Nissan-Logo.png',
    locations: [26],
    address: {
      street: 'Lyngbyvej',
      number: '24',
      city: 'København Ø',
      zipCode: '2100',
    },
    gtmId: 'GTM-M599TBSH',
    faviconPath: '/substores/nissan/favicon.png',
    newsletterListId: 'XZLagb',
  },
  'trio-biler-ballerup': {
      subdomain: '',
      name: 'Trio Biler Ballerup',
      brandName: 'Trio Biler',
      legalName: 'TRIO BILER ApS',
      cvr: '31771749',
      contactEmail: 'hej+triobiler@justdrive.today',
      logoUrl: 'https://ruwqcqtlonwdksotboxk.supabase.co/storage/v1/object/public/logos/trio.png', // Placeholder attached logo
      locations: [16, 35],
      address: {
        street: 'Energivej',
        number: '22',
        city: 'Ballerup',
        zipCode: '2750',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'autohuset-vestergaard-kolding': {
      subdomain: '',
      name: 'Autohuset Vestergaard',
      brandName: 'Autohuset Vestergaard',
      legalName: 'Autohuset Vestergaard Personvogne A/S',
      cvr: '189305791',
      contactEmail: 'hej+autohuset-vestergaard@justdrive.today',
      logoUrl: 'https://ruwqcqtlonwdksotboxk.supabase.co/storage/v1/object/public/logos/Autohusetvestergaard.png', // attached logo
      locations: [34, 3, 8],
      address: {
        street: 'Trianglen',
        number: '20',
        city: 'Kolding',
        zipCode: '6000',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'krogsgaard-biler': {
      subdomain: '',
      name: 'Krogsgaard Biler',
      brandName: 'Krogsgaard Biler',
      legalName: 'KROGSGAARD-JENSEN A/S',
      cvr: '21481483',
      contactEmail: 'hej+krogsgaard-biler@justdrive.today',
      logoUrl: 'https://ruwqcqtlonwdksotboxk.supabase.co/storage/v1/object/public/logos/Krogsgaard.png',
      locations: [24, 30],
      address: {
        street: 'Mesterlodden',
        number: '37',
        city: 'Gentofte',
        zipCode: '2820',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'pedersen-&-nielsen': {
      subdomain: '',
      name: 'Pedersen & Nielsen A/S',
      brandName: 'Pedersen & Nielsen',
      legalName: 'Pedersen & Nielsen Automobilforretning A/S',
      cvr: '14854738',
      contactEmail: 'hej+pedersen-nielsen-biler@justdrive.today',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos/pn_logo.png',
      locations: [38],
      address: {
        street: 'Ribevej',
        number: '3',
        city: 'Randers SV',
        zipCode: '8940',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'pma-biler': {
      subdomain: '',
      name: 'PMA biler A/S',
      brandName: 'PMA Biler Roskilde',
      legalName: 'PMABiler A/S',
      cvr: '30435028',
      contactEmail: 'hej+pmabiler@justdrive.today',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//PMA_logo.png',
      locations: [39],
      address: {
        street: 'Præstemarksvænge',
        number: '16',
        city: 'Roskilde',
        zipCode: '4000',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'koch-biler': {
      subdomain: '',
      name: 'Koch Biler A/S Viby J',
      brandName: 'Koch Biler',
      legalName: 'Koch Biler A/S',
      cvr: '25623185',
      contactEmail: 'hej+kochbiler@justdrive.today',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//Koch_Biler_logo.png',
      locations: [37],
      address: {
        street: 'Gunnar Clausens Vej',
        number: '12',
        city: 'Viby J',
        zipCode: '8260',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'andersen-biler': {
      subdomain: '',
      name: 'Andersen Biler - Roskilde',
      brandName: 'Andersen Biler',
      legalName: 'Andersen Biler A/S',
      cvr: '17694707',
      contactEmail: 'hej+andersenbiler@justdrive.today',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//Andersen_biler_logo.png',
      locations: [36],
      address: {
        street: 'Københavnsvej',
        number: '154',
        city: 'Roskilde',
        zipCode: '4000',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
    'bjarne-nielsen': {
      subdomain: '',
      name: 'Bjarne Nielsen A/S Hillerød',
      brandName: 'Bjarne Nielsen A/S',
      legalName: 'Bjarne Nielsen A/S',
      cvr: '31943140',
      contactEmail: 'hej+bjarnenielsen@justdrive.today',
      logoUrl: 'https://tnpupoiskmcvqymuaunc.supabase.co/storage/v1/object/public/logos//bjarne_nielsen.png',
      locations: [40],
      address: {
        street: 'Falkevej',
        number: '4A',
        city: 'Hillerød',
        zipCode: '3400',
      },
      gtmId: '',
      faviconPath: '',
      newsletterListId: '',
      primaryColor: '',
      secondaryColor: '',
      contrastColor: '',
      domain: ''
    },
} as const satisfies KeysInProd

// 

const allSubStores = {
  prod,
  dev
}

export const substores = allSubStores[process.env.NEXT_PUBLIC_SUBSTORE_CONFIG_ENVIRONMENT as keyof typeof allSubStores] as KeysInProd


const getCurrentSubstore = () => {
  const subdomain = process.env.NEXT_PUBLIC_SUBDOMAIN as keyof Substore
  if (!subdomain) return null
  return substores[subdomain] ?? null
}

export const substoreSettings = getCurrentSubstore()

export const getBrandName = (alternative: string) => {
  return substoreSettings?.brandName ?? alternative
}

const getNewsletterListId = () => {
  // Justdrive main newsletter list id.
  return substoreSettings?.newsletterListId ?? 'Vs6jcg'
}

export const newsLetterListId = getNewsletterListId()

export const getDomain = () => {
  return substoreSettings?.domain ?? 'https://justdrive.today'
}

// Utility function to check for uniqueness of the `subdomain` field
function validateUniqueSubdomains(stores: Substore): boolean {
  const subdomainSet = new Set<string>()
  for (const store in stores) {
    if (subdomainSet.has(store)) {
      throw new Error(`Duplicate subdomain found: ${store}`)
    }
    subdomainSet.add(store)
  }
  return true
}

// Call this to validate
validateUniqueSubdomains(substores)
